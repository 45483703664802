@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.sc-hLQSwg {
  max-height: 100vh !important;
  outline: none;
  overflow-y: auto !important;
  padding: 0;
  position: fixed !important;
  top: calc(0px);
  will-change: transform;
  z-index: 9999 !important;
}

.sc-hLQSwg ::-webkit-scrollbar {
  display: none !important;
}

.h-38px {
  height: 38px !important;
}

ul {
  padding: 0px;
}

.btn-primary {
  background-color: #181d3d !important;
}

.logo_name .logo_image {
  height: 80px;
  width: 80px;
}

.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  /* left: 40px; */
  padding-left: 80px;
  width: 100%;
  transition: all 0.5s ease;
  z-index: 2;
}

.home-section .text {
  display: inline-block;
  color: #181d3d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

.signup_modal button.btn-close {
  filter: invert(1);
  opacity: 1;
}

.signup_modal .btn-close:focus {
  box-shadow: none;
}

.commmon-modal {
  background-color: rgba(94, 101, 136, 0.9);
  bottom: 0;
  content: "";
  left: 0;
  /* position: absolute; */
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* display: block;
  z-index: 9999; */
}

.sign_up_modal .modal-header {
  background-color: var(--color-primary);
  border-radius: 50%;
  border: none;
  height: 50px;
  position: absolute;
  right: -20px;
  text-align: center;
  top: -20px;
  width: 50px;
  z-index: 9;
}

.sign_up_modal .modal-header button.close {
  margin-top: -5px;
  margin-right: 0;
  margin-left: 4px;
  opacity: 1;
  padding: 0;
}

.sign_up_modal .modal-header button.close span {
  color: #ffffff;
}

.sign_up_modal .modal-header button.close span:focus {
  border: none;
  outline: none;
  text-shadow: none;
}

.modal-header .btn-close {
  margin: unset;
  color: #1d1b31;
}

.modal-header .btn-close {
  margin: unset;
}

.bgc-thm {
  background-color: #1d1b31 !important;
  color: #ffffff !important;
}

.my_profile_setting_input.form-group {
  margin-bottom: 30px;
}

.my_profile_setting_input label,
.my_profile_setting_textarea label {
  font-size: 16px;
  font-family: "Nunito";
  color: rgb(72, 72, 72);
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 10px;
}

.my_profile_setting_input .form-control,
.my_profile_setting_input .form-select {
  /* background-color: rgb(255, 255, 255); */
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  height: 52px;
  padding-left: 20px;
}

.my_profile_setting_input .form-control::placeholder {
  font-size: 14px;
  font-family: "Nunito";
  color: rgb(72, 72, 72);
  line-height: 1.2;
}

.my_profile_setting_textarea {
  margin-bottom: 25px;
}

.my_profile_setting_input .btn1 {
  background-color: rgba(255, 90, 95, 0);
  border: 2px solid rgb(255, 90, 95);
  border-radius: 8px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  font-size: 16px;
  font-family: "Nunito";
  color: rgb(255, 90, 95);
  font-weight: bold;
  line-height: 1.2;
  height: 50px;
  margin-right: 30px;
  width: 200px;
}

.my_profile_setting_input .btn1:hover {
  background-color: rgb(255, 90, 95);
  color: #ffffff;
}

.my_profile_setting_input .btn2 {
  background-color: rgb(255, 90, 95);
  border: 2px solid rgb(255, 90, 95);
  border-radius: 8px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  font-size: 16px;
  font-family: "Nunito";
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 1.2;
  height: 50px;
  width: 200px;
}

.my_profile_setting_input .btn2:hover {
  color: rgb(255, 90, 95);
  background-color: #ffffff;
  border: 2px solid rgb(255, 90, 95);
}

.my_profile_setting_input .btn3 {
  background-color: rgb(29, 41, 62);
  border: 2px solid rgb(29, 41, 62);
  border-radius: 8px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  font-size: 16px;
  font-family: "Nunito";
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 1.2;
  height: 50px;
  width: 200px;
}

.my_profile_setting_input .btn3:hover {
  border: 2px solid rgb(29, 41, 62);
  color: rgb(29, 41, 62);
  background-color: #ffffff;
}

.my_profile_setting_input2 label {
  font-size: 14px;
  font-family: "Open Sans";
  color: rgb(10, 10, 10);
  line-height: 1.2;
}

.my_profile_setting_input .form-control:focus {
  outline: none;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.height {
  height: 330px;
}

.signature-label {
  padding-left: 50px;
}

.stamp-label {
  padding-left: 16px;
}

table,
th,
td {
  border: 1px solid black;
}

.sidebar-svg {
  width: 23px !important;
}

.table-primary {
  --bs-table-color: #ffff !important;
  --bs-table-bg: #181d3d !important;
}

a {
  color: #ffffff !important;
  text-decoration: none !important;
}

.scroll-tab {
  overflow-x: scroll;
  flex-wrap: unset;
  white-space: nowrap;
}

.scroll-tab::-webkit-scrollbar {
  height: 15px;
  width: 20px;
}

.scroll-tab::-webkit-scrollbar-track {
  background: transparent;
}

.scroll-tab::-webkit-scrollbar-thumb {
  background-color: #181d3d;
  border-radius: 50px;
  border: 3px solid #fff;
}

.btn-thm {
  background-color: #181d3d !important;
  border: 2px solid #181d3d;
  border-radius: 10px !important;
  color: #ffffff !important;
  -webkit-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.btn-thm.rounded {
  background-color: #181d3d !important;
  border-radius: 5px !important;
  color: #ffffff !important;
}

.btn-thm:hover,
.btn-thm:active {
  background-color: #ffffff !important;
  border-color: #181d3d !important;
  color: #181d3d !important;
}

.btn-thm:hover svg {
  fill: #181d3d !important;
}

.btn-thm svg {
  fill: #ffffff !important;
}

.btn-thm.white:hover,
.btn-thm.white:active,
.btn-thm.white:focus {
  background-color: #181d3d;
  border-color: #181d3d;
  color: #ffffff;
}

.btn-thmdangerdisable {
  background-color: #ffffff !important;
  border-color: #bb2d3b !important;
  color: #bb2d3b !important;
  border: 2px solid #bb2d3b;
  border-radius: 10px !important;
  -webkit-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.btn-thmdangerdisable.rounded {
  background-color: #bb2d3b !important;
  border-radius: 5px !important;
  color: #ffffff !important;
}

.btn-thmdangerdisable:hover,
.btn-thmdangerdisable:active {
  background-color: #ffffff !important;
  border-color: #bb2d3b !important;
  color: #bb2d3b !important;
}

.btn-thmdangerdisable:hover svg {
  fill: #bb2d3b !important;
}

.btn-thmdangerdisable.white:hover,
.btn-thmdangerdisable.white:active,
.btn-thmdangerdisable.white:focus {
  background-color: #bb2d3b;
  border-color: #bb2d3b;
  color: #ffffff;
}

.btn-thmdanger {
  background-color: #bb2d3b !important;
  border: 2px solid #bb2d3b;
  border-radius: 10px !important;
  color: #ffffff !important;
  -webkit-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.btn-thmdanger.rounded {
  background-color: #bb2d3b !important;
  border-radius: 5px !important;
  color: #ffffff !important;
}

.btn-thmdanger:hover,
.btn-thmdanger:active {
  background-color: #ffffff !important;
  border-color: #bb2d3b !important;
  color: #bb2d3b !important;
}

.btn-thmdanger:hover svg {
  fill: #bb2d3b !important;
}

.btn-thmdanger.white:hover,
.btn-thmdanger.white:active,
.btn-thmdanger.white:focus {
  background-color: #bb2d3b;
  border-color: #bb2d3b;
  color: #ffffff;
}

.btn-thmgreen {
  background-color: #00a267 !important;
  border: 2px solid #00a267;
  border-radius: 10px !important;
  color: #ffffff !important;
  -webkit-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.btn-thmgreen.rounded {
  background-color: #00a267 !important;
  border-radius: 5px !important;
  color: #ffffff !important;
}

.btn-thmgreen:hover,
.btn-thmgreen:active {
  background-color: #ffffff !important;
  border-color: #00a267 !important;
  color: #00a267 !important;
}

.btn-thmgreen:hover svg {
  fill: #00a267 !important;
}

.btn-thmgreen.white:hover,
.btn-thmgreen.white:active,
.btn-thmgreen.white:focus {
  background-color: #00a267;
  border-color: #00a267;
  color: #ffffff;
}

.btn-thmgreendisabled {
  background-color: #ffffff !important;
  border-color: #00a267 !important;
  color: #00a267 !important;
  border: 2px solid #00a267;
  border-radius: 10px !important;
  -webkit-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.btn-thmgreendisabled.rounded {
  background-color: #00a267 !important;
  border-radius: 5px !important;
  color: #ffffff !important;
}

.btn-thmgreendisabled:hover,
.btn-thmgreendisabled:active {
  background-color: #ffffff !important;
  border-color: #00a267 !important;
  color: #00a267 !important;
}

.btn-thmgreendisabled:hover svg {
  fill: #00a267 !important;
}

.btn-thmgreendisabled.white:hover,
.btn-thmgreendisabled.white:active,
.btn-thmgreendisabled.white:focus {
  background-color: #00a267;
  border-color: #00a267;
  color: #ffffff;
}

.portfolio_upload {
  background-color: #ffff;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  height: 223px;
  margin-bottom: 30px;
  text-align: center;
  position: relative;
}

.portfolio_upload .btn {
  color: #ffffff;
  cursor: pointer;
  padding: 8px 20px;
  font-size: 46px;
  font-weight: normal;
}

.portfolio_upload .icon {
  font-size: 48px;
  margin-top: 50px;
}

.portfolio_upload .icon {
  color: var(--color-primary);
  font-size: 48px;
  margin-top: 50px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.portfolio_upload p {
  font-size: 22px;
  font-family: "Nunito";
  color: rgb(111, 109, 109);
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
  position: relative;
}

.portfolio_upload input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 1;
  height: 100%;
  cursor: pointer;
}

.portfolio_upload .pdfextention {
  transform: rotate(0deg) !important;
}

.single_img_upload {
  width: 100%;
}

.single_img_upload .list-inline-item .portfolio_item>span:first-child {
  width: 100% !important;
  height: 100% !important;
}

.ht_right_widget ul li.list-inline-item:nth-child(2) {
  border-left: 1px solid #333333;
  border-right: 1px solid #333333;
  margin-left: 10px;
  padding-right: 15px;
  text-align: center;
}

.ht_left_widget ul li.list-inline-item:first-child {
  margin-right: 25px;
}

.listing_single_property_compare ul li.list-inline-item {
  margin-right: 25px;
  text-align: center;
}

.single_img_upload .list-inline-item .portfolio_item>span:first-child {
  width: 100% !important;
  height: 100% !important;
}

.imaage-upload-ul {
  padding: 0 !important;
}

.portfolio_item {
  border-radius: 8px;
  background-color: #fff;
  height: 200px;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  width: 200px;
}

.portfolio_item img {
  height: 100%;
  width: 200px;
}

.portfolio_item .edu_stats_list {
  border-radius: 8px;
  background-color: rgb(255, 90, 95);
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 10px;
  width: 35px;
}

.portfolio_item .edu_stats_list span {
  color: rgb(255, 255, 255);
}

.studImage-preview {
  height: 60px !important;
  width: 60px !important;
}

#backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgb(0 0 0 / 29%);
}

.spinner-border {
  display: block;
  position: fixed;
  top: calc(50% - (58px / 2));
  right: calc(50% - (58px / 2));
  color: #181d3d;
}

.error {
  color: #bf213e !important;
}

.form-control,
.form-select,
.form-check-input {
  border: 1px solid #181d3d !important;
}

.form-outline {
  margin-bottom: 15px !important;
}

.outline-image {
  border: 1px solid #181d3d;
}

.filter-box {
  width: 200px !important;
}

.filter-div {
  margin-top: 65px;
}

@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}

@media print {
  button {
    display: none !important;
  }
}

.page-link {
  background-color: #181d3d !important;
}

.dt-empty {
  display: none !important;
}

.margin-for-search {
  margin-bottom: 50px;
}

.center-status-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* login page */

.back-half-img {
  background-image: url(../public/Images/login-rectangle.png);
  background-repeat: no-repeat;
  object-fit: cover;
  height: 100vh !important;
  background-size: 100% 100%;
}

.login-page-div {
  padding: 0 !important;
  position: relative;
}

.login-form {
  position: absolute;
  top: 15%;
  right: 6%;
  /* padding: 10px 10px; */
}

.login-container {
  background-color: #ffffff;
  border-radius: 20px;
  width: 94%;
  height: 490px;
}

.shadowbox-shadow {
  /* width: 250px; */
  /* box-shadow: 10px 50px 50px 10px rgba(0, 0, 0, 0.19), 5px 50px 50px 5px rgba(0, 0, 0, 0.19); */
  box-shadow: 5px 10px 10px 5px rgba(0, 0, 0, 0.19);
  /* text-align: center; */
}

.login-title {
  font-size: 50px;
  /* font-weight: 50PX; */
}

.login-btn {
  border-radius: 30px !important;
}

.login-inputbox {
  border: 0 !important;
  border-bottom: 2px solid #a6a8b3 !important;
  border-radius: 0px !important;
}

.login-icons {
  border-radius: 0 !important;
  background-color: #ffffff !important;
  border-bottom: 2px solid #a6a8b3;
  border-radius: 0px;
}

.login-form-box {
  padding-left: 26px;
  padding-right: 26px;
}

.school-logo {
  width: 85%;
}

/* sidevbra cssf */

.fTiwzx {
  width: 3.9rem !important;
}

.sidebar-scroll {
  overflow-y: auto;
}

.fee-form-field {
  width: 400px !important;
}

.parent-table {
  margin-top: 120px;
}

.progress-modal {
  /* min-width: 800px !important; */
  overflow-x: auto;
}

.table-responsive {
  /* ensure that only the text scrolls, not the button */
  flex: 1;
  overflow: auto;
}

.fab {
  position: absolute;
  right: 20px;
  top: 200px;
  color: white;
  background-color: blue;
}

.dt-length,
.dt-info {
  display: none;
}

.dt-paging {
  position: absolute;
  right: 35px;
  bottom: 25px;
}

.dt-export {
  position: absolute;
  left: 117px;
  bottom: 25px;
}

.filterfield {
  margin-right: 5px;
}

.mainpage-padding {
  padding-right: 0 !important;
}

.position-absolute-for-sidenav {
  transform: translateX(-91px);
  visibility: hidden;
  transition-duration: 400ms;
}

.sidebar-animation:hover .position-absolute-for-sidenav {
  visibility: visible;
  transform: translate(0%, 0px);
  transition-duration: 400ms;
  z-index: 999;
}

.page_padding {
  padding-right: 0.5rem !important;
  padding-left: 1.5rem !important;
}

.css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}

.css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  display: none !important;
}

#dt-search-1{
  margin: 0px !important;
}

.mobile-navbar{
  background-color: #e4e9f7;
}

.address-width{
  width: 272px !important;
}

@media screen and (max-width: 768px) {
  .home-section {
    padding-left: 0px;
  }

  .dt-export {
    left: 23px;
    bottom: 25px;
  }
  
  .dt-search{
    width: 180px !important;
  }

}


@media screen and (max-width: 565px) {
  .fee-form-field {
    width: 250px !important;
  }

  .syllabus_search {
    margin-top: 80px !important;
  }

  .dt-paging {
    /* right: 15px; */
    left: 23px;
    bottom: -25px;
  }


}

@media screen and (max-width: 500px) {
  .add-btn {
    width: 100% !important;
  }

  .filter-box {
    width: 100% !important;
  }

  .dt-search{
    width: 100% !important;
  }
}

@media screen and (max-width: 370px) {
  .fee-form-field {
    width: 200px !important;
  }

  .paging_full_numbers {
    right: 15px;
    bottom: -25px;
  }

}