body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Basic styling */

:root {
  /* Colors */
  --black: #09090c;
  --grey: #a4b2bc;
  --white: #fff;
  --background: rgba(137, 171, 245, 0.37);
}

html ::-webkit-scrollbar {
  height: 10px;
  width: 15px;
}

html ::-webkit-scrollbar-track {
  background: transparent;
}

html ::-webkit-scrollbar-thumb {
  background-color: #181d3d;
  border-radius: 20px;
  border: 3px solid #fff;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--background);
  font-family: "Poppins", sans-serif;
}